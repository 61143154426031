import { graphql } from 'gatsby';
import React from 'react';
import AwardsList from '../components/AwardList';
import Header from '../styles/Header';
import SEO from '../components/SEO';

const AwardsPage = ({ data }) => {
  const awards = data.awards.edges;

  return (
    <>
      <SEO title="Premi" />
      <Header>
        <h1>Premi</h1>
      </Header>
      <AwardsList awards={awards} />
    </>
  );
};

export default AwardsPage;

export const query = graphql`
  query AwardsQuery {
    awards: allSanityAward(sort: { fields: year, order: DESC }) {
      edges {
        node {
          id
          award
          year
          projects {
            name
            slug {
              current
            }
          }
        }
      }
    }
  }
`;
