import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Table = styled.div`
  max-width: 100rem;
  width: 85%;
  margin: clamp(5rem, 13vw, 10rem) auto 0;

  section:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media (min-width: 800px) {
    margin: 0 auto;
  }
`;

const TableHeader = styled.span`
  display: grid;
  grid-template-columns: 8rem 1fr 1fr;

  @media (min-width: 800px) {
    grid-template-columns: 20rem 1fr 1fr;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr 1fr;
  border-bottom: 1px solid var(--green-light);
  padding: 1.5rem 0;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 800px) {
    grid-template-columns: 20rem 1fr 1fr;
  }
`;

const AwardsList = ({ awards }) => (
  <>
    <Table>
      <TableHeader>
        <span className="subtitle">anno</span>
        <span className="subtitle">posto</span>
        <span className="subtitle">progetto</span>
      </TableHeader>
      {awards.map((award) => (
        <TableRow key={award.node.id}>
          <p>{award.node.year}</p>
          <p>{award.node.award}</p>
          {award.node.projects[0] ? (
            <Link
              className="bold"
              to={`/architecture/${award.node.projects[0].slug.current}`}
            >
              {award.node.projects[0].name}
            </Link>
          ) : null}
        </TableRow>
      ))}
    </Table>
  </>
);

export default AwardsList;
